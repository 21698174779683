import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10')
];

export const server_loads = [];

export const dictionary = {
		"/": [~2],
		"/actueel/[[year=year]]/[[month=month]]": [~4],
		"/actueel/[categorySlug]/[articleSlug]": [~5],
		"/advocaten": [~6],
		"/advocaten/[employeeSlug]": [~7],
		"/contact": [8],
		"/kantoor/[[pageSlug]]": [9],
		"/over-ons/[[pageSlug]]": [10],
		"/[pageSlug]/[[subPageSlug]]": [~3]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';